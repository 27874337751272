a{
    transition: color .1s ease-in-out;
}
.sidebar {
    position: fixed;
    padding-top: 50px;
    z-index: 1010;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
.sidebar, .sidebar-bg {
    background: #2d353c;
    width: 210px;
    left: 0;
    top: 0;
    bottom: 0;
}
.sidebar .nav {
    display: block;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .sidebar .nav>li {
    position: relative;
  }
  .sidebar .nav>li.active>a, .sidebar .nav>li.active>a:focus, .sidebar .nav>li.active>a:hover {
    color: #fff;
    background: #242a31;
  }
  .sidebar .nav>li.active>a {
    position: relative;
    z-index: 10;
  }
  .sidebar .nav>li>a {
    padding: 15px 20px;
    line-height: 20px;
    color: #a8acb1;
    display: block;
    text-decoration: none;
}
  .sidebar .nav>li:after, .sidebar .nav>li:before, .sidebar .nav>li>a:after, .sidebar .nav>li>a:before {
    content: '';
    clear: both;
    display: table;
}

.sidebar .nav>li>a i {
    float: left;
    margin-right: 10px;
    width: 14px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
}

.sidebar .nav>li>a .badge {
    margin-top: 1px;
    padding: 3px 8px;
    background: #1b1f24;
    font-weight: 600;
    font-size: 10px;
}
.pull-right {
    float: right!important;
}
.sidebar li>a .caret {
    display: block;
    float: right;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 11px;
    border: none;
}
.sidebar .sub-menu {
    list-style-type: none;
    padding: 5px 0 10px 39px;
    margin: 0;
    position: relative;
    display: none;
}
.sidebar .sub-menu>li {
    position: relative;
}
.sidebar-minify-btn {
    margin: 10px 0;
    float: right;
    padding: 5px 20px 5px 10px!important;
    background: #1b1f24;
    color: #fff;
    border-radius: 20px 0 0 20px;
}
.sidebar li>a:hover{
    color: #fff;
}