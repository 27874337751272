.wrapper{
    display: flex;
    justify-content: center;
    max-width: 768px;
    margin: auto;
    height: 100vh;
    padding: 30px;
    box-sizing: border-box;
    
}

.survey-div{
    width: 100%;
}

.survey-div  .form-div{
    margin: auto;
}

.reno-div{
    display: flex;
}

.renovation .form-check{
    display: flex;
    align-items: center;
}

.renovation input[type='radio']{
    width: 30px;
    height: 30px;
    position: relative;
    margin-right: 5px;
}

.renovation .form-group, .renovation .formgroup{
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 1.5em;
    
}