body {
  background: #d9e0e7;
  font-size: 14px;
  font-family: 'Open Sans',"Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #707478;
  line-height: 1.42857143;
  -webkit-font-smoothing: antialiased;
}


*{
  box-sizing: border-box;
}

a{
  color: inherit;
}

.no-drop{
  cursor: no-drop;
}

.lang-select{
  border: 0px;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.lang-select:active, .lang-select:focus, .lang-select:visited{
  border: 0px;
  outline: 0;
}

.sv_header__text{
  text-align: center;
  font-size: 1.2em;
  font-weight: 900;
}

.sv_container{
  max-width: 768px!important;
  margin: auto!important;
  font-size: 1.39em!important;
}