.content {
    margin-left: 220px;
    padding: 20px;
    margin-top: 50px;
}

.content .pull-right {
    float: right!important;
}

.breadcrumb {
    padding: 0;
    margin: 0;
    /* background: 0 0; */
}

.breadcrumb>li {
    line-height: 32px;
}

.breadcrumb>li a {
    color: #333;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.page-header {
    font-size: 24px;
    margin: 0 0 15px;
    padding: 0;
    border: none;
    line-height: 32px;
    font-weight: 500;
    color: #242a30;
}

.page-header small {
    line-height: 20px;
    color: #6a7786;
}

.row {
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
}

.widget.widget-stats {
    position: relative;
}

.bg-danger,
.bg-red {
    background-color: #ff5b57 !important;
}

.bg-purple-like{
    background-color: #9966ff !important;
}

.bg-blue{
    background-color: #36A2EB !important;
}

.widget {
    color: #fff;
    padding: 15px;
    overflow: hidden;
    border-radius: 3px;
    margin-bottom: 20px;
}

.widget-stats .stats-icon {
    font-size: 42px;
    height: 56px;
    width: 56px;
    text-align: center;
    line-height: 56px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: .15;
}

.widget-stats .stats-info h4 {
    font-size: 12px;
    margin: 5px 0;
    color: #fff;
    color: rgba(255, 255, 255, .7);
}

.widget-stats .stats-info p {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
}

.widget-stats .stats-link a {
    display: block;
    margin: 15px -15px -15px;
    padding: 7px 15px;
    background: rgba(0, 0, 0, .4);
    text-align: right;
    color: #fff;
    color: rgba(255, 255, 255, .7);
    text-decoration: none;
}

.bg-orange,
.bg-warning {
    background-color: #f59c1a !important;
}

.bg-grey-darker {
    background-color: #929ba1 !important;
}

.bg-black-lighter {
    background-color: #575d63 !important;
}

.row>[class^=col-].ui-sortable {
    min-height: 50px;
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 3px;
}

.ui-sortable .panel-heading {
    cursor: move;
}

.panel-inverse>.panel-heading {
    background: #242a30;
}

.panel-title {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 20px;
}

.panel-title,
.panel-title .pull-right {
    line-height: 20px;
}

.panel-heading {
    padding: 10px 15px;
    border: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #fff;
}

.panel-body {
    padding: 15px;
}

.height-sm {
    height: 300px !important;
}

.panel-heading-btn {
    float: right;
}

.panel-heading-btn>a {
    margin-left: 8px;
}

.btn-icon .btn-xs {
    width: 16px;
    height: 16px;
    font-size: 8px;
    line-height: 16px;
}

.btn .btn-xs {
    line-height: 18px;
    padding: 1px 5px;
}

.btn-circle,
.btn .btn-circle {
    border-radius: 100px;
}

.btn .btn-danger,
.btn .btn-danger .disabled,
.btn .btn-danger .disabled:focus,
.btn.btn-danger.disabled:hover,
.btn .btn-danger[disabled],
.btn .btn-danger[disabled]:focus,
.btn .btn-danger[disabled]:hover {
    color: #fff;
    background: #ff5b57;
    border-color: #ff5b57;
}

.btn-icon,
.btn .btn-icon {
    display: inline-block;
    width: 28px;
    height: 28px;
    padding: 0;
    border: none;
    text-align: center;

}

.btn {
    font-weight: 600;
    line-height: 20px;
    padding: 6px 12px;
    transition: all .1s ease-in-out;
    box-shadow: none !important;
}

.display {
    display: block;
}

.no-display {
    display: none !important;
}

.f-s-12 {
    font-size: 12px !important;
}

@media print {
    body {
        background-color: #d9e0e7;
    }

    .content {
        margin-left: 0px !important;
        padding: 20px 20px;
        margin-top: 50px;
    }
}