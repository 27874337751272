
*{
    box-sizing: border-box;
}

.header .navbar-default {
    background: #fff;
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1020;
    margin-bottom: 0;
    padding: 0;
    transition: box-shadow .2s linear;
    text-decoration: none;
    background: #fff;
}

.header .navbar-brand {
    float: left;
    margin-right: 0px;
    width: 210px;
    font-weight: 100;
    font-size: 18px;
    text-decoration: none;
}

.header .navbar-toggle {
    position: relative;
    float: right;
    padding: 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: none;
    border-radius: 4px;
    outline: 0;
}

.header .navbar-nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: block;
}

.header .navbar-nav > li >a {
    display: block;
    text-decoration: none;
    line-height: 20px;
    padding: 15px 25px;
}

.header .navbar-nav > li > a > i {
    top: 1px;
    position: relative;
    font-size: 16px;
}

.header .navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    border-top: 1px solid #f2f3f4;
    box-shadow: 0 13px 25px -2px rgb(0 0 0 / 20%);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.dropdown-menu {
    border: none;
    box-shadow: 0 5px 30px 0 rgb(0 0 0 / 25%);
    font-size: 14px;
}

.dropdown-divider, .dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.header .navbar-nav > li > a {
    display: block;
    text-decoration: none;
    line-height: 20px;
    padding: 15px 25px;
}

.header .navbar-user img {
    float: left;
    width: 30px;
    height: 30px;
    margin: -5px 10px 0 0;
    border-radius: 30px;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 6px solid\9;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}

.header .navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    border-top: 1px solid #f2f3f4;
    box-shadow: 0 13px 25px -2px rgb(0 0 0 / 20%);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.dropdown-item, .dropdown-menu > li >a {
    display: block;
    padding: 5px 15px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none
}

.header .navbar-nav > li >a:focus, .header .navbar-nav > li > a:hover {
    opacity: .6;
}




