.login--page {
    background: rgb(33, 102, 13);
    background: -moz-linear-gradient(135deg, rgba(33, 102, 13, 1) 28%, rgba(41, 68, 164, 1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(33, 102, 13, 1) 28%, rgba(41, 68, 164, 1) 100%);
    background: linear-gradient(135deg, rgba(33, 102, 13, 1) 28%, rgba(41, 68, 164, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#21660d", endColorstr="#2944a4", GradientType=1);
    position: absolute;
    inset: 0;
    justify-content: center;
}

.login--section {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.login--section .login--div {
    width: 400px;
    border: solid 2px #fff;
    padding: 30px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(51px);
    -webkit-backdrop-filter: blur(5px);
    color: #fff;
    position: relative;
}

.logo--section{
    position: absolute;
    height: 70px;
    inset: 0;
    z-index: -99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-div{
    height: 100%;
    width: 100%;
    display: flex;
    margin-top: -90px;
    justify-content: center;
    align-items: center;
}

.logo{
    background-color: #fff;
    padding-right: 15px;
}

.login--div .form-group {
    background-color: transparent !important;
    position: relative;
}

.loading {
    width: 20px;
    height: 20px;
    text-align: center;
    margin: auto;
    border-radius: 50%;
    border: solid 2px transparent;
    border-top: solid 2px #fff;
    animation: rotate 0.5s linear 0s infinite;
}

.btn-login {
    width: 65px !important;
    height: 38px !important;
    text-align: center !important;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 768px) {
    .login--section .login--div {
        width: 95%;
    }
}